import { AuthLoginReqDto } from '../../../api/models/auth-login-req-dto';
import { AuthForgotPasswordReqDto } from '../../../api/models/auth-forgot-password-req-dto';
import { AuthAuthByTokenReqDto } from '../../../api/models/auth-auth-by-token-req-dto';
import { AuthConfirmForgotPasswordReqDto } from '../../../api/models/auth-confirm-forgot-password-req-dto';
import { AuthLoginResDto } from '../../../api/models/auth-login-res-dto';
import { AuthSwitchTenantReqDto } from '../../../api/models/auth-switch-tenant-req-dto';
import { TenantsListResDto } from '../../../api/models/tenants-list-res-dto';
import { TfaQuestionsReqDto } from '../../../api/models/tfa-questions-req-dto';
import { TfaCompleteSetupOptionsReqDto } from '../../../api/models/tfa-complete-setup-options-req-dto';
import { TfaRecoveryVerifyPhoneNumberReqDto } from '../../../api/models/tfa-recovery-verify-phone-number-req-dto';
import { TfaPhoneNumberDto } from '../../../api/models/tfa-phone-number-dto';
import { TfaCheckCodeReqDto } from '../../../api/models/tfa-check-code-req-dto';
import { TfaRecoveryCheckAnswerReqDto } from '../../../api/models/tfa-recovery-check-answer-req-dto';
import { TfaOptionsDeleteReqDto } from '../../../api/models/tfa-options-delete-req-dto';
import { TfaGetOptionsResDto } from '../../../api/models/tfa-get-options-res-dto';

export class SetPushNotificationsToken {
  static readonly type = '[Auth] SetPushNotificationsToken';
  constructor(public payload: string) {}
}

export class AssignPushNotificationsToken {
  static readonly type = '[Auth] AssignPushNotificationsToken';
  constructor(public payload: void) {}
}

export class UnAssignPushNotificationsToken {
  static readonly type = '[Auth] UnAssignPushNotificationsToken';
  constructor(public payload: void) {}
}

/** Login action **/
export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: AuthLoginReqDto) {}
}

/** Check auth action **/
export class AuthByToken {
  static readonly type = '[Auth] AuthByToken';
  constructor(public payload: AuthAuthByTokenReqDto) {}
}

/** called when cookies & state need to be filled **/
export class ProceedLogin {
  static readonly type = '[Auth] ProceedLogin';
  constructor(public payload: AuthLoginResDto) {}
}

export class SwitchTenant {
  static readonly type = '[Auth] SwitchTenant';
  constructor(public payload: AuthSwitchTenantReqDto | any) {}
}

export class SetTenantsList {
  static readonly type = '[Auth] SetTenantsList';
  constructor(public payload: Array<TenantsListResDto>) {}
}

/** Logout action **/
export class Logout {
  static readonly type = '[Auth] Logout';
  constructor(public payload: {}) {}
}

/** Register action **/
export class Register {
  static readonly type = '[Auth] Register';
  constructor(public payload: any) {}
}

/** Forgot password action **/
export class ForgotPassword {
  static readonly type = '[Auth] ForgotPassword';
  constructor(public payload: AuthForgotPasswordReqDto) {}
}

/** Confirm user by token action **/
export class UserConfirm {
  static readonly type = '[Auth] UserConfirm';
  constructor(public payload: { token: string }) {}
}

/** Check token (used for reset password on page load) **/
export class ResetPasswordCheck {
  static readonly type = '[Auth] ResetPasswordCheck';
  constructor(public payload: { token: string }) {}
}

/** Confirm forgot password **/
export class ConfirmForgotPassword {
  static readonly type = '[Auth] ConfirmForgotPassword';
  constructor(public payload: AuthConfirmForgotPasswordReqDto) {}
}

/** Send invitation action**/
export class TenantInvite {
  static readonly type = '[Auth] TenantInvite';
  constructor(public payload) {}
}

/** Send invitation action**/
export class InviteUsersToTenantAndSpaces {
  static readonly type = '[Auth] InviteUsersToTenant';
  constructor(
    public payload: {
      users: {
        email: string;
        spaces: { spaceId: string; roleName: String }[];
      }[];
    },
  ) {}
}

/** validate invitation token for new users action **/
export class TenantInviteTokenCheck {
  static readonly type = '[Auth] TenantInviteTokenCheck';
  constructor(public payload) {}
}

/** confirm invitation for new users action **/
export class TenantInviteConfirmNew {
  static readonly type = '[Auth] TenantInviteConfirmNew';
  constructor(public payload) {}
}

/** Update has unread **/
export class HasUnreadNotificationsUpdate {
  static readonly type = '[Auth] HasUnreadNotificationsUpdate';
  constructor(public payload) {}
}

/** Update user **/
export class UpdateAuthUser {
  static readonly type = '[Auth] UpdateAuthUser';
  constructor(public payload) {}
}

export class SetEnv {
  static readonly type = '[Auth] Set Env';
  constructor(public payload: any) {}
}

export class SetPlatform {
  static readonly type = '[Auth] Set Platform';
  constructor(public payload: string) {}
}

export class SetPlatformOS {
  static readonly type = '[Auth] Set Platform OS';
  constructor(public payload: string) {}
}

export class LogoutProcess {
  static readonly type = '[Auth] - Logout process';
  constructor(public payload) {}
}

export class RecoveryAccountEmail {
  static readonly type = '[Auth] - Recovery account email';
  constructor(public payload) {}
}

export class RenewAccount {
  static readonly type = '[Auth] - Renew account';
  constructor(public payload) {}
}

export class TwoFaSetOption {
  static readonly type = '[Auth] - Set two-fa option';
  constructor(public payload: TfaGetOptionsResDto) {}
}

export class TwoFaClearOption {
  static readonly type = '[Auth] - Clear two-fa option';
  constructor() {}
}

export class TwoFaGetOption {
  static readonly type = '[Auth] - Get two-fa option';
  constructor() {}
}

export class TwoFaDeleteOption {
  static readonly type = '[Auth] - Delete two-fa option';
  constructor(public payload: TfaOptionsDeleteReqDto) {}
}

export class TwoFaVerifyPhoneNumber {
  static readonly type = '[Auth] - Verify two-fa phone number';
  constructor(public payload: TfaPhoneNumberDto) {}
}

export class TwoFaCheckVerificationCode {
  static readonly type = '[Auth] - Check two-fa verification code';
  constructor(public payload: TfaCheckCodeReqDto) {}
}

export class TwoFaCompleteSetupOption {
  static readonly type = '[Auth] - Complete two-fa setup option';
  constructor(public payload: TfaCompleteSetupOptionsReqDto) {}
}

export class TwoFaGetQuestionList {
  static readonly type = '[Auth] - Get two-fa question list';
  constructor() {}
}

export class TwoFaCreateQuestion {
  static readonly type = '[Auth] - Create own two-fa question';
  constructor(public payload: TfaQuestionsReqDto) {}
}

export class TwoFaRemoveQuestion {
  static readonly type = '[Auth] - Remove own two-fa question';
  constructor(public payload: { id: string }) {}
}

export class TwoFaRecoveryCheckAnswer {
  static readonly type = '[Auth] - Recovery two-fa check answer';
  constructor(public payload: TfaRecoveryCheckAnswerReqDto) {}
}

export class TwoFaRecoveryVerifyPhoneNumber {
  static readonly type = '[Auth] - Recovery two-fa verify phone number';
  constructor(public payload: TfaRecoveryVerifyPhoneNumberReqDto) {}
}

export class TwoFaRecoveryComplete {
  static readonly type = '[Auth] - Recovery two-fa complete';
  constructor(public payload: TfaCheckCodeReqDto) {}
}

/** Expired Session action **/
export class ExpiredSession {
  static readonly type = '[Auth] Expired session';
  constructor(public payload: { sessionUuid: string; loginData?: AuthLoginResDto }) {}
}
