import { InvitedMembers } from '../../data/object-creation';
import { ProjectsCreateReqDto } from '../../../api/models/projects-create-req-dto';
import { ProjectOrderUpdateReqDto } from '../../../api/models/project-order-update-req-dto';

/** Create project action **/
export class ProjectCreate {
  static readonly type = '[Projects] Create';
  constructor(
    public payload: {
      body: ProjectsCreateReqDto;
      avatar?: any;
      invitedUsers?: InvitedMembers;
    },
  ) {}
}

/** Get projects action **/
export class ProjectsSet {
  static readonly type = '[Projects] Set';
  constructor() {}
}

/** Get projects action **/
export class ProjectsGet {
  static readonly type = '[Projects] Get';
  constructor(public payload) {}
}

/** Update project action **/
export class ProjectUpdate {
  static readonly type = '[Projects] Update';
  constructor(public payload) {}
}

/** Delete project action **/
export class ProjectDelete {
  static readonly type = '[Projects] Delete';
  constructor(public payload) {}
}

export class ProjectsDeleteBySpace {
  static readonly type = '[Projects] Delete By Space';
  constructor(public payload) {}
}

/** Get users list action **/
export class ProjectGetUsersList {
  static readonly type = '[Projects] GetUsersList';
  constructor(public payload) {}
}

export class ProjectGetUserListsByProjectsId {
  static readonly type = '[Project] GetUserListsByProjectsId';
  constructor(public payload) {}
}

/** Send invitation action**/
export class ProjectInvite {
  static readonly type = '[Projects] Invite';
  constructor(public payload) {}
}

/** validate invitation token for new users action **/
export class ProjectInviteTokenCheck {
  static readonly type = '[Projects] ProjectInviteTokenCheck';
  constructor(public payload) {}
}

/** confirm invitation for existing users action **/
export class ProjectInviteConfirmExist {
  static readonly type = '[Projects] ProjectInviteConfirmExist';
  constructor(public payload) {}
}

/** confirm invitation for new users action **/
export class ProjectInviteConfirmNew {
  static readonly type = '[Projects] ProjectInviteConfirmNew';
  constructor(public payload) {}
}

/** Update project avatar action **/
export class ProjectUpdateAvatar {
  static readonly type = '[Projects] Update Avatar';
  constructor(public payload) {}
}

export class ProjectSetAvatarImageUploadLoading {
  static readonly type = '[Projects] Set Project Avatar Image upload Loading';
  constructor(public payload) {}
}

export class ProjectSetInfo {
  static readonly type = '[Projects] Set Project info';
  constructor(public payload) {}
}

export class ProjectsEmojiPicker {
  static readonly type = '[Projects] EmojiPicker';
  constructor(public payload) {}
}

export class ProjectsSetEmoji {
  static readonly type = '[Projects] SetEmoji';
  constructor(public payload) {}
}
export class ProjectsUnassignMember {
  static readonly type = '[Projects] UnassignMember';
  constructor(public payload) {}
}

export class ProjectsRevokeInvite {
  static readonly type = '[Projects] RevokeInvite';
  constructor(public payload) {}
}

/** Update projects after change project action **/
export class ProjectsUpdateAfterChange {
  static readonly type = '[Projects] UpdateAfterChange';
  constructor(public payload) {}
}

/** Update projects after delete space action **/
export class ProjectsUpdateAfterDelete {
  static readonly type = '[Projects] UpdateAfterDelete';
  constructor(public payload) {}
}

/** Reset project numberOfUnreadActivityLogs **/
export class ProjectsResetNumberOfActivities {
  static readonly type = '[Projects] ResetNumberOfActivities';
  constructor(public payload) {}
}

/** Archive space **/
export class ProjectToggleArchiveStatus {
  static readonly type = '[Project] ToggleArchiveStatus';
  constructor(public payload) {}
}

/** Archive project **/
export class ProjectRestore {
  static readonly type = '[Project] RestoreProject';
  constructor(public payload) {}
}

/** Update project order for member action **/
export class ProjectOrderUpdate {
  static readonly type = '[Project] MembersOrderUpdate';
  constructor(public payload) {}
}

export class ProjectOrders {
  static readonly type = '[Project] ProjectOrders';

  constructor(
    public payload: {
      actionProjectId?: string;
      projects: Array<ProjectOrderUpdateReqDto>;
    },
  ) {}
}

/** Leave project action **/
export class ProjectLeave {
  static readonly type = '[Project] Project leave';
  constructor(public payload) {}
}

/** Deleted Project member action socket **/
export class ProjectMemberDelete {
  static readonly type = '[Project] Project member delete';
  constructor(
    public payload: {
      projectId: string;
      userId: string;
      isCurrentUserDeleted: boolean;
    },
  ) {}
}
