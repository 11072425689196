import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class OnlineOfflineService {
  public isOnline = true;
  constructor(private toastr: ToastrService) {
    window.addEventListener('offline', () => this.showOfflineToast());
    window.addEventListener('online', () => this.hideOfflineToast());
  }

  private showOfflineToast() {
    this.isOnline = false;
  }

  private hideOfflineToast() {
    this.isOnline = true;
    this.toastr.clear();
  }
}
