import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class CustomizerService {
  constructor(private config: ConfigService) {}

  switchLayout(layout = 'Light') {
    const conf = this.config.templateConf;
    conf.layout.variant = layout;
    this.config.applyTemplateConfigChange({ layout: conf.layout });
  }
}
