export class TenantExists {
  static readonly type = '[Tenants] Tenant exists';

  constructor(public payload: { name: string }) {}
}

export class TenantClear {
  static readonly type = '[Tenants] Tenant clear';
}

export class TenantsSetList {
  static readonly type = '[Tenants] Set tenants list';

  constructor(public payload) {}
}

export class TenantsGetList {
  static readonly type = '[Tenants] Get tenants list';
}
