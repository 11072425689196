export const TwoFARoutes = {
  Enable: '/two-factor-authentication',
  Disable: '/two-factor-authentication/disable',
  PhoneNumber: '/two-factor-authentication/phone-number',
  Success: '/two-factor-authentication/success',
  VerificationCode: 'two-factor-authentication/verification-code',
  Question: '/two-factor-authentication/question',
};

export const TwoFAKeys = {
  LoginData: 'two-fa-login-data',
  PhoneNumber: 'phoneNumber',
  VerifyPhoneRequestToken: 'tfaVerifyPhoneRequestToken',
  VerifyPhoneSuccessToken: 'tfaVerifyPhoneSuccessToken',
  RecoveryToken: 'tfaRecoveryToken',
  RecoveryAnswerSuccessToken: 'tfaRecoveryAnswerSuccessToken',
  TenantName: 'tenant',
  Email: 'email',
  Password: 'password',
};
