import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';

export class MixpanelService {
  private static instance: MixpanelService;
  mixpanelToken = environment.mixpanelToken;

  private constructor() {
    mixpanel.init(this.mixpanelToken, {
      debug: environment.production ? false : true,
    });
  }

  static getInstance(): MixpanelService {
    if (!MixpanelService.instance) {
      MixpanelService.instance = new MixpanelService();
    }
    return MixpanelService.instance;
  }

  static trackEvent(eventName: string, properties?: { [key: string]: any }): void {
    mixpanel.track(eventName, properties);
  }

  static trackPageView(pageName: string, properties?: { [key: string]: any }): void {
    mixpanel.track('Page View: ' + pageName, { pageName: pageName, ...properties });
  }

  static setUser(userId: string, userProperties?: any): void {
    mixpanel.identify(userId);
    if (userProperties) {
      mixpanel.people.set(userProperties);
    }
  }

  static setSuperProperties(properties: { [key: string]: any }): void {
    mixpanel.register(properties);
  }

  static identifyUser(userId: string, userProperties?: { [key: string]: any }): void {
    // Finally, set the user ID as the primary identifier
    mixpanel.identify(userId);

    // Set user properties if provided
    if (userProperties) {
      mixpanel.people.set(userProperties);
    }
  }
}
