/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { TfaCheckCodeReqDto } from '../models/tfa-check-code-req-dto';
import { TfaCompleteSetupOptionsReqDto } from '../models/tfa-complete-setup-options-req-dto';
import { TfaGetOptionsResDto } from '../models/tfa-get-options-res-dto';
import { TfaOptionsCreateReqDto } from '../models/tfa-options-create-req-dto';
import { TfaOptionsDbDto } from '../models/tfa-options-db-dto';
import { TfaOptionsDeleteReqDto } from '../models/tfa-options-delete-req-dto';
import { TfaOptionsUpdateReqDto } from '../models/tfa-options-update-req-dto';
import { TfaOptionsValidateReqDto } from '../models/tfa-options-validate-req-dto';
import { TfaPhoneNumberDto } from '../models/tfa-phone-number-dto';
import { TfaRecoveryAnswerSuccessTokenDto } from '../models/tfa-recovery-answer-success-token-dto';
import { TfaRecoveryCheckAnswerReqDto } from '../models/tfa-recovery-check-answer-req-dto';
import { TfaRecoveryVerifyPhoneNumberReqDto } from '../models/tfa-recovery-verify-phone-number-req-dto';
import { TfaVerifyPhoneRequestTokenDto } from '../models/tfa-verify-phone-request-token-dto';
import { TfaVerifyPhoneSuccessTokenDto } from '../models/tfa-verify-phone-success-token-dto';

@Injectable({
  providedIn: 'root',
})
export class TfaOptionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation tfaOptionsGet
   */
  static readonly TfaOptionsGetPath = '/tfa-options';

  /**
   * get tfa options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaOptionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tfaOptionsGet$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<TfaGetOptionsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaOptionsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaGetOptionsResDto>;
        }),
      );
  }

  /**
   * get tfa options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaOptionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tfaOptionsGet(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<TfaGetOptionsResDto> {
    return this.tfaOptionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<TfaGetOptionsResDto>) => r.body as TfaGetOptionsResDto),
    );
  }

  /**
   * Path part for operation tfaOptionsCreate
   */
  static readonly TfaOptionsCreatePath = '/tfa-options';

  /**
   * create tfa options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaOptionsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsCreateReqDto;
  }): Observable<StrictHttpResponse<TfaOptionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaOptionsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaOptionsDbDto>;
        }),
      );
  }

  /**
   * create tfa options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaOptionsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsCreateReqDto;
  }): Observable<TfaOptionsDbDto> {
    return this.tfaOptionsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TfaOptionsDbDto>) => r.body as TfaOptionsDbDto),
    );
  }

  /**
   * Path part for operation tfaOptionsUpdate
   */
  static readonly TfaOptionsUpdatePath = '/tfa-options';

  /**
   * update tfa options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaOptionsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsUpdateReqDto;
  }): Observable<StrictHttpResponse<TfaOptionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaOptionsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaOptionsDbDto>;
        }),
      );
  }

  /**
   * update tfa options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaOptionsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsUpdateReqDto;
  }): Observable<TfaOptionsDbDto> {
    return this.tfaOptionsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TfaOptionsDbDto>) => r.body as TfaOptionsDbDto),
    );
  }

  /**
   * Path part for operation tfaOptionsValidate
   */
  static readonly TfaOptionsValidatePath = '/tfa-options/validate';

  /**
   * validate answer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaOptionsValidate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsValidate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsValidateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaOptionsValidatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * validate answer
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaOptionsValidate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsValidate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsValidateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.tfaOptionsValidate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation tfaOptionsDelete
   */
  static readonly TfaOptionsDeletePath = '/tfa-options/delete';

  /**
   * delete tfa options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaOptionsDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsDeleteReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaOptionsDeletePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete tfa options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaOptionsDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaOptionsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaOptionsDeleteReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.tfaOptionsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation tfaVerifyPhoneNumber
   */
  static readonly TfaVerifyPhoneNumberPath = '/tfa-options/verify-phone-number';

  /**
   * verify phone number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaVerifyPhoneNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaVerifyPhoneNumber$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaPhoneNumberDto;
  }): Observable<StrictHttpResponse<TfaVerifyPhoneRequestTokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaVerifyPhoneNumberPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaVerifyPhoneRequestTokenDto>;
        }),
      );
  }

  /**
   * verify phone number
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaVerifyPhoneNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaVerifyPhoneNumber(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaPhoneNumberDto;
  }): Observable<TfaVerifyPhoneRequestTokenDto> {
    return this.tfaVerifyPhoneNumber$Response(params).pipe(
      map((r: StrictHttpResponse<TfaVerifyPhoneRequestTokenDto>) => r.body as TfaVerifyPhoneRequestTokenDto),
    );
  }

  /**
   * Path part for operation tfaCheckVerificationCode
   */
  static readonly TfaCheckVerificationCodePath = '/tfa-options/check-verification-code';

  /**
   * check verification code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaCheckVerificationCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaCheckVerificationCode$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaCheckCodeReqDto;
  }): Observable<StrictHttpResponse<TfaVerifyPhoneSuccessTokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaCheckVerificationCodePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaVerifyPhoneSuccessTokenDto>;
        }),
      );
  }

  /**
   * check verification code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaCheckVerificationCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaCheckVerificationCode(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaCheckCodeReqDto;
  }): Observable<TfaVerifyPhoneSuccessTokenDto> {
    return this.tfaCheckVerificationCode$Response(params).pipe(
      map((r: StrictHttpResponse<TfaVerifyPhoneSuccessTokenDto>) => r.body as TfaVerifyPhoneSuccessTokenDto),
    );
  }

  /**
   * Path part for operation tfaCompleteSetupOptions
   */
  static readonly TfaCompleteSetupOptionsPath = '/tfa-options/complete-setup-options';

  /**
   * complete setup options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaCompleteSetupOptions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaCompleteSetupOptions$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaCompleteSetupOptionsReqDto;
  }): Observable<StrictHttpResponse<TfaOptionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaCompleteSetupOptionsPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaOptionsDbDto>;
        }),
      );
  }

  /**
   * complete setup options
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaCompleteSetupOptions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaCompleteSetupOptions(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaCompleteSetupOptionsReqDto;
  }): Observable<TfaOptionsDbDto> {
    return this.tfaCompleteSetupOptions$Response(params).pipe(
      map((r: StrictHttpResponse<TfaOptionsDbDto>) => r.body as TfaOptionsDbDto),
    );
  }

  /**
   * Path part for operation tfaRecoveryCheckAnswer
   */
  static readonly TfaRecoveryCheckAnswerPath = '/tfa-options/recovery-check-answer';

  /**
   * recoveryCheckAnswer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaRecoveryCheckAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaRecoveryCheckAnswer$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaRecoveryCheckAnswerReqDto;
  }): Observable<StrictHttpResponse<TfaRecoveryAnswerSuccessTokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaRecoveryCheckAnswerPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaRecoveryAnswerSuccessTokenDto>;
        }),
      );
  }

  /**
   * recoveryCheckAnswer
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaRecoveryCheckAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaRecoveryCheckAnswer(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaRecoveryCheckAnswerReqDto;
  }): Observable<TfaRecoveryAnswerSuccessTokenDto> {
    return this.tfaRecoveryCheckAnswer$Response(params).pipe(
      map((r: StrictHttpResponse<TfaRecoveryAnswerSuccessTokenDto>) => r.body as TfaRecoveryAnswerSuccessTokenDto),
    );
  }

  /**
   * Path part for operation tfaRecoveryVerifyPhoneNumber
   */
  static readonly TfaRecoveryVerifyPhoneNumberPath = '/tfa-options/recovery-verify-phone-number';

  /**
   * recovery verify phone number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaRecoveryVerifyPhoneNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaRecoveryVerifyPhoneNumber$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaRecoveryVerifyPhoneNumberReqDto;
  }): Observable<StrictHttpResponse<TfaVerifyPhoneRequestTokenDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaRecoveryVerifyPhoneNumberPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaVerifyPhoneRequestTokenDto>;
        }),
      );
  }

  /**
   * recovery verify phone number
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaRecoveryVerifyPhoneNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaRecoveryVerifyPhoneNumber(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaRecoveryVerifyPhoneNumberReqDto;
  }): Observable<TfaVerifyPhoneRequestTokenDto> {
    return this.tfaRecoveryVerifyPhoneNumber$Response(params).pipe(
      map((r: StrictHttpResponse<TfaVerifyPhoneRequestTokenDto>) => r.body as TfaVerifyPhoneRequestTokenDto),
    );
  }

  /**
   * Path part for operation tfaRecoveryComplete
   */
  static readonly TfaRecoveryCompletePath = '/tfa-options/recovery-complete';

  /**
   * recovery complete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaRecoveryComplete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaRecoveryComplete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaCheckCodeReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaOptionsService.TfaRecoveryCompletePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * recovery complete
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaRecoveryComplete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaRecoveryComplete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaCheckCodeReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.tfaRecoveryComplete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
