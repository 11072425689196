/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { TenantsGetListResDto } from '../models/tenants-get-list-res-dto';

@Injectable({
  providedIn: 'root',
})
export class TenantsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation tenantsGetList
   */
  static readonly TenantsGetListPath = '/tenants';

  /**
   * get spaces list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<TenantsGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TenantsService.TenantsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TenantsGetListResDto>>;
        }),
      );
  }

  /**
   * get spaces list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<TenantsGetListResDto>> {
    return this.tenantsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenantsGetListResDto>>) => r.body as Array<TenantsGetListResDto>),
    );
  }

  /**
   * Path part for operation tenantExists
   */
  static readonly TenantExistsPath = '/tenants/tenants-exists';

  /**
   * check if tenants exists or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantExists$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    name: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TenantsService.TenantExistsPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('name', params.name, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * check if tenants exists or not
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantExists(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    name: string;
  }): Observable<CommonSuccessResDto> {
    return this.tenantExists$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
