import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';

import { environment } from '../../../../environments/environment';
import { AuthStateModel } from '../models/AuthState';
import { AuthLoginResDto } from '../../../api/models/auth-login-res-dto';
import { CommonSuccessResDto } from '../../../api/models/common-success-res-dto';
import { AuthService } from '../../../api/services/auth.service';
import { RedirectService } from '../../services/redirect.service';
import { SocketsService } from '../../services/sockets.service';
import { CustomizerService } from '../../services/customizer.service';
import {
  AssignPushNotificationsToken,
  AuthByToken,
  ConfirmForgotPassword,
  ExpiredSession,
  ForgotPassword,
  HasUnreadNotificationsUpdate,
  InviteUsersToTenantAndSpaces,
  Login,
  Logout,
  LogoutProcess,
  ProceedLogin,
  RecoveryAccountEmail,
  Register,
  RenewAccount,
  ResetPasswordCheck,
  SetEnv,
  SetPlatform,
  SetPlatformOS,
  SetPushNotificationsToken,
  SetTenantsList,
  SwitchTenant,
  TenantInvite,
  TenantInviteConfirmNew,
  TenantInviteTokenCheck,
  TwoFaCheckVerificationCode,
  TwoFaClearOption,
  TwoFaCompleteSetupOption,
  TwoFaCreateQuestion,
  TwoFaDeleteOption,
  TwoFaGetOption,
  TwoFaGetQuestionList,
  TwoFaRecoveryCheckAnswer,
  TwoFaRecoveryComplete,
  TwoFaRecoveryVerifyPhoneNumber,
  TwoFaRemoveQuestion,
  TwoFaSetOption,
  TwoFaVerifyPhoneNumber,
  UnAssignPushNotificationsToken,
  UpdateAuthUser,
  UserConfirm,
} from '../actions/auth.action';
import { SpacesGet } from '../actions/spaces.action';
import { ProjectsGet } from '../actions/projects.action';
import { OnlineStatusService } from '../../services/online-status.service';
import { RouterTenantPipe } from '../../pipes/router-tenant.pipe';
import { Router } from '@angular/router';
import { TenantClear } from '../actions/tenants.action';
import { TfaQuestionsService } from '../../../api/services/tfa-questions.service';
import { TfaQuestionsDbDto } from '../../../api/models/tfa-questions-db-dto';
import { TfaOptionsService } from '../../../api/services/tfa-options.service';
import { TwoFAKeys } from '../../../pages/auth-pages/two-factor-authentication/two-factor-authentication.constants';
import { TfaGetOptionsResDto } from '../../../api/models/tfa-get-options-res-dto';
import {
  TwoFactorAuthenticationService,
  VerificationSteps,
} from '../../../pages/auth-pages/two-factor-authentication/two-factor-authentication.service';
import { ConfigService } from '../../services/config.service';
import { TranslocoService } from '@ngneat/transloco';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@State<AuthStateModel>({
  name: 'Auth',
  defaults: {
    pushNotificationsToken: null,
    user: null,
    accessToken: null,
    refreshToken: null,
    permissions: null,
    inviteData: null,
    tenants: [],
    env: null,
    platform: 'web',
    platformOS: 'web',
    twoFaQuestions: [],
    lastAddedQuestion: null,
    twoFaOptions: null,
    session: null,
  },
})
@Injectable()
export class AuthState {
  private domain: string | undefined = environment.subdomains
    ? environment.main_host.split(':')[0]
    : undefined;

  @Selector()
  /**
   * get push notifications token (used for mobile)
   * @param  {AuthStateModel} state
   */
  static getPushNotificationsToken(state: AuthStateModel) {
    return state.pushNotificationsToken;
  }

  @Selector()
  /**
   * get tenants (used for mobile)
   * @param  {AuthStateModel} state
   */
  static getTenantList(state: AuthStateModel) {
    return state.tenants;
  }

  @Selector()
  /**
   * get roles list
   * @param  {AuthStateModel} state
   */
  static getRoles(state: AuthStateModel) {
    return state.user.roles as any[];
  }

  @Selector()
  /**
   * get permissions list
   * @param  {AuthStateModel} state
   */
  static getPermissions(state: AuthStateModel) {
    return state.permissions;
  }

  @Selector()
  /**
   * get logged in user
   * @param  {AuthStateModel} state
   */
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  /**
   * get invite data
   * @param  {AuthStateModel} state
   */
  static getInviteData(state: AuthStateModel) {
    return state.inviteData;
  }

  @Selector()
  /**
   * Check if user is logged in
   * @param  {AuthStateModel} state
   * @returns boolean
   */
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.accessToken !== null;
  }

  @Selector()
  /**
   * Get access token (if any)
   * @param  {AuthStateModel} state
   * @returns string
   */
  static getAccessToken(state: AuthStateModel): string {
    return state.accessToken;
  }

  @Selector()
  static getPlatform(state: AuthStateModel): string {
    return state.platform;
  }

  @Selector()
  static getPlatformOS(state: AuthStateModel): string {
    return state.platformOS;
  }

  @Selector()
  static getEnv(state: AuthStateModel): any {
    return state.env;
  }

  @Selector()
  static getTwoFaQuestions(state: AuthStateModel): Array<TfaQuestionsDbDto> {
    return state.twoFaQuestions;
  }

  @Selector()
  static getTwoFaOptions(state: AuthStateModel): TfaGetOptionsResDto {
    return state.twoFaOptions;
  }

  @Selector()
  static getSessionUuid(state: AuthStateModel): string {
    return state.session;
  }

  constructor(
    private authService: AuthService,
    private store: Store,
    private redirectService: RedirectService,
    private customizerService: CustomizerService,
    private socketsService: SocketsService,
    private readonly localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private onlineStatus: OnlineStatusService,
    private tfaQuestionsService: TfaQuestionsService,
    private tfaOptionsService: TfaOptionsService,
    private twoFaService: TwoFactorAuthenticationService,
    private router: Router,
    private configService: ConfigService,
    private translocoService: TranslocoService,
    protected routerTenantPipe: RouterTenantPipe,
  ) {}

  /**
   * Set push notifications token
   * @param  {getState}: StateContext<AuthStateModel>
   * @param  {SetPushNotificationsToken} action
   */
  @Action(SetPushNotificationsToken)
  set_push_notifications_token(
    { patchState }: StateContext<AuthStateModel>,
    action: SetPushNotificationsToken,
  ) {
    patchState({
      pushNotificationsToken: action.payload,
    });
  }

  /**
   * Set tenants list
   * @param  {getState}: StateContext<AuthStateModel>
   * @param  {SetTenantsList} action
   */
  @Action(SetTenantsList)
  set_tenants_list({ patchState }: StateContext<AuthStateModel>, action: SetTenantsList) {
    patchState({
      tenants: action.payload,
    });
  }

  /**
   * Assign push notifications token
   * @param  {getState}: StateContext<AuthStateModel>
   * @param  {AssignPushNotificationsToken} action
   */
  @Action(AssignPushNotificationsToken)
  assign_push_notifications_token(
    { getState }: StateContext<AuthStateModel>,
    action: AssignPushNotificationsToken,
  ) {
    const state = getState();
    if (state.pushNotificationsToken) {
      return this.authService.loginDeviceToken({
        body: { token: state.pushNotificationsToken },
      });
    }
  }

  /**
   * Unassign push notifications token
   * @param  {getState}: StateContext<AuthStateModel>
   * @param  {UnAssignPushNotificationsToken} action
   */
  @Action(UnAssignPushNotificationsToken)
  unassign_push_notifications_token(
    { getState }: StateContext<AuthStateModel>,
    action: UnAssignPushNotificationsToken,
  ) {
    const state = getState();
    if (state.pushNotificationsToken) {
      return this.authService.logoutDeviceToken({
        body: { token: state.pushNotificationsToken },
      });
    }
  }

  /**
   * Login action handlers
   * @param  {}: StateContext<AuthStateModel>
   * @param  {Login} action
   */
  @Action(Login)
  login({ patchState }: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login({ body: action.payload }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.localStorageService.remove(VerificationSteps.DisableTwoFa);
          patchState({ accessToken: null });

          if (!result.accessToken && result.tfaRecoveryToken) {
            this.twoFaService.loginData = {
              tfaRecoveryToken: result.tfaRecoveryToken,
              question: result.question,
              phoneNumber: result.phoneNumber,
              tenant: action.payload.tenantName,
              email: action.payload.email,
              password: action.payload.password,
            };
            this.onlineStatus.userIsAuthorized(true);
            this.twoFaService.verificationStep = VerificationSteps.Login;
            this.localStorageService.set('authData', {
              tfaRecoveryToken: result.tfaRecoveryToken,
              question: result.question,
              phoneNumber: result.phoneNumber,
              tenant: action.payload.tenantName,
              email: action.payload.email,
              password: action.payload.password,
            });
          } else {
            this.cookieService.set('accessToken', result.accessToken, null, '/', this.domain);
            this.cookieService.set('refreshToken', result.refreshToken, null, '/', this.domain);
            this.cookieService.set('userId', result.user._id, null, '/', this.domain);
            this.localStorageService.set('x-session-uuid', result.session);
            this.store.dispatch(new ProceedLogin(result));
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Login action handler
   * @param  {patchState}: StateContext<AuthStateModel>
   * @param  {ProceedLogin} action
   */
  @Action(ProceedLogin)
  proceed_login({ patchState }: StateContext<AuthStateModel>, action: ProceedLogin) {
    patchState({ ...action.payload });
    this.localStorageService.remove('accessToken');
    this.localStorageService.remove('refreshToken');
    this.localStorageService.remove('userId');

    this.localStorageService.set('accessToken', action.payload.accessToken);
    this.localStorageService.set('refreshToken', action.payload.refreshToken);
    this.localStorageService.set('userId', action.payload.user?._id);
    this.socketsService.connect(action.payload.accessToken, action.payload.refreshToken);
    this.onlineStatus.userIsAuthorized(true);
    this.customizerService.switchLayout(action.payload.user?.uiTheme);
    this.store.dispatch([
      SpacesGet,
      ProjectsGet,
      // TODO: for mobile
      // ChatsGet,
      // ThreadGetList,
      AssignPushNotificationsToken,
    ]);
  }

  /**
   * Switch tenant action handlers
   * @param  {}: StateContext<AuthStateModel>
   * @param  {SwitchTenant} action
   */
  @Action(SwitchTenant)
  switchTenant({ patchState }: StateContext<AuthStateModel>, action: SwitchTenant) {
    return this.authService.switchTenant({ body: action.payload }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.localStorageService.set('x-session-uuid', result.session);
          if (!environment.subdomains) {
            this.store.dispatch(new ProceedLogin(result)).subscribe(() => {
              if (!action.payload.isPush) {
                this.router
                  .navigate([this.routerTenantPipe.transform('dash', result.user.tenantName)])
                  .then(() => window.location.reload());
              }
            });
          } else {
            this.redirectService.go(result.user.tenantName, 'dash');
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Logout action handler
   * @param  { patchState }: StateContext<AuthStateModel>
   */
  @Action(Logout)
  logout({ patchState, getState, dispatch }: StateContext<AuthStateModel>) {
    const state = getState();
    const body = {
      pushNotificationsToken: state.pushNotificationsToken,
    };
    return this.authService.logout({ body }).pipe(
      tap(
        (result) => {
          dispatch(new LogoutProcess(result));
        },
        () => {
          this.localStorageService.remove('x-session-uuid');
          if (!environment.subdomains) {
            this.router.navigate([this.routerTenantPipe.transform('/auth/login', 'root')]);
          } else {
            this.redirectService.go(state.user.tenantName, 'auth/login');
          }
        },
      ),
    );
  }

  /**
   * Logout process
   * @param { }: StateContext<AuthStateModel>
   * @param  {LogoutProcess} action
   */
  @Action(LogoutProcess)
  logout_process(
    { patchState, getState, dispatch }: StateContext<AuthStateModel>,
    action: LogoutProcess,
  ) {
    const result = action.payload;

    dispatch(new UnAssignPushNotificationsToken()).subscribe(() => {
      if (result.success || result.ok) {
        // for mobile
        this.localStorageService.remove('x-session-uuid');
        //
        this.localStorageService.remove('accessToken');
        this.localStorageService.remove('refreshToken');
        this.localStorageService.remove('userId');
        patchState({ user: null, accessToken: null, refreshToken: null });
        if (!environment.subdomains) {
          if (result.success) {
            dispatch(new TenantClear()).subscribe(() => {
              this.router.navigate([this.routerTenantPipe.transform('/auth/login', 'root')]);
            });
          } else {
            this.router.navigate([this.routerTenantPipe.transform('/auth/login', 'root')]);
          }
        } else {
          this.redirectService.go('', 'auth/login');
        }
      } else {
        if (!environment.subdomains) {
          if (result?.user) {
            this.store.dispatch(new ProceedLogin(result)).subscribe(() => {
              this.router
                .navigate([this.routerTenantPipe.transform('/dash', result.user.tenantName)])
                .then(() => window.location.reload());
            });
          } else {
            this.localStorageService.remove('x-session-uuid');
            this.localStorageService.remove('accessToken');
            this.localStorageService.remove('refreshToken');
            this.localStorageService.remove('userId');
            this.router.navigate(['/']).then(() => window.location.reload());
          }
        } else {
          this.localStorageService.remove('accessToken');
          this.localStorageService.remove('refreshToken');
          this.localStorageService.remove('userId');
          this.redirectService.go(result.user.tenantName, 'dash');
        }
      }
    });
  }

  /**
   * Register action handler
   * @param { }: StateContext<AuthStateModel>
   * @param  {Register} action
   */
  @Action(Register)
  register({}: StateContext<AuthStateModel>, action: Register) {
    return this.authService.register({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Forgot password action handler
   * @param {}: StateContext<AuthStateModel>
   * @param  {ForgotPassword} action
   */
  @Action(ForgotPassword)
  forgot_password({}: StateContext<AuthStateModel>, action: ForgotPassword) {
    return this.authService.forgotPassword({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Recovery email send action handler
   * @param {}: StateContext<AuthStateModel>
   * @param  {RecoveryAccountEmail} action
   */
  @Action(RecoveryAccountEmail)
  recovery_email_send({}: StateContext<AuthStateModel>, action: RecoveryAccountEmail) {
    return this.authService.renewAccount({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Auth by token - used on page load to validate access token & refresh if needed
   * @param {getState}: StateContext<AuthStateModel>
   * @param  {AuthByToken} action
   */
  @Action(AuthByToken)
  auth_by_token({ getState }: StateContext<AuthStateModel>, action: AuthByToken) {
    this.cookieService.deleteAll('/', this.domain);
    const { pushNotificationsToken } = getState();
    return this.authService
      .authByToken({ body: { ...action.payload, pushNotificationsToken } })
      .pipe(
        tap(
          (result: AuthLoginResDto) => {
            let tenant = '';
            this.localStorageService.set('x-session-uuid', result.session);

            // Set language
            const language = result.user?.localization || 'en';
            if (language) {
              const conf = this.configService.templateConf;
              this.configService.applyTemplateConfigChange({
                layout: conf.layout,
                language,
              });
              this.translocoService.setActiveLang(language);
            }

            if (environment.subdomains) {
              tenant = window.location.host.split('.')[0];
              if (tenant === environment.base_host.split('.')[0]) {
                tenant = '';
              }
              // if (result.user.tenantName !== tenant) {
              //   this.redirectService.go(result.user.tenantName, 'dash');
              // }
            }

            MixpanelService.identifyUser(result.user._id, {
              email: result.user.email,
              name: result.user.name,
              created_at: result.user.created_at,
              localization: result.user.localization,
              timezone: result.user.timezone,
              uiTheme: result.user.uiTheme,
              updated_at: result.user.updated_at,
              userName: result.user.userName,
            });

            MixpanelService.setSuperProperties({ tenant: result.user.tenantName });

            this.store.dispatch(new ProceedLogin(result));
          },
          (err) => {
            this.localStorageService.remove('x-session-uuid');
            this.localStorageService.remove('accessToken');
            this.localStorageService.remove('refreshToken');
            this.localStorageService.remove('userId');
            catchError(err);
          },
        ),
      );
  }

  /**
   * User Confirm is called when user confirms registration by passing token back to frontend via url
   * @param { }: StateContext<AuthStateModel>
   * @param  {UserConfirm} action
   */
  @Action(UserConfirm)
  user_confirm({}: StateContext<AuthStateModel>, action: UserConfirm) {
    return this.authService.userConfirm(action.payload).pipe(
      tap(
        (result: AuthLoginResDto) => {
          // for mobile
          this.localStorageService.set('x-session-uuid', result.session);
          //
          this.store.dispatch(new ProceedLogin(result));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * ResetPasswordCheck is used on reset password form for validating token before asking for new password
   * @param {getState, setState}: StateContext<AuthStateModel>
   * @param  {ResetPasswordCheck} action
   */
  @Action(ResetPasswordCheck)
  check_jwt_token_health({}: StateContext<AuthStateModel>, action: ResetPasswordCheck) {
    return this.authService.resetPasswordCheck(action.payload).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * ConfirmForgotPassword is used on reset password form for validating token before asking for new password
   * @param {getState, setState}: StateContext<AuthStateModel>
   * @param  {ConfirmForgotPassword} action
   */
  @Action(ConfirmForgotPassword)
  confirm_forgot_password(
    { getState, setState }: StateContext<AuthStateModel>,
    action: ConfirmForgotPassword,
  ) {
    return this.authService.resetPassword({ body: action.payload }).pipe(
      tap(
        (result: CommonSuccessResDto) => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * RenewAccount is used on reset account form for validating token before asking for new password
   * @param {getState, setState}: StateContext<AuthStateModel>
   * @param  {RenewAccount} action
   */
  @Action(RenewAccount)
  renew_account({ getState, setState }: StateContext<AuthStateModel>, action: RenewAccount) {
    return this.authService.renewAccountConfirm({ body: action.payload }).pipe(
      tap(
        (result: CommonSuccessResDto) => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite users action handler
   * @param  { }: StateContext<AuthStateModel>
   */
  @Action(TenantInvite)
  tenant_invite({}: StateContext<AuthStateModel>, action: TenantInvite) {
    return this.authService.tenantInvite({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite users to tenand and spaces action handler
   * @param  { }: StateContext<AuthStateModel>
   */
  @Action(InviteUsersToTenantAndSpaces)
  inviteUsersToTenantAndSpaces(
    {}: StateContext<AuthStateModel>,
    action: InviteUsersToTenantAndSpaces,
  ) {
    return this.authService.inviteUsersToTenantAndSpaces({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite token validation action handler
   * @param  {getState, setState}: StateContext<AuthStateModel>
   */
  @Action(TenantInviteTokenCheck)
  tenant_invite_token_check(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TenantInviteTokenCheck,
  ) {
    return this.authService.tenantsInviteTokenCheck({ token: action.payload.token }).pipe(
      tap(
        (result) => {
          patchState({
            inviteData: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Register by invitation link
   * @param { }: StateContext<AuthStateModel>
   * @param  {TenantInviteConfirmNew} action
   */
  @Action(TenantInviteConfirmNew)
  tenant_invite_confirm_new({}: StateContext<AuthStateModel>, action: TenantInviteConfirmNew) {
    return this.authService.tenantConfirm({ body: action.payload }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.store.dispatch(new ProceedLogin(result));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update has unread notifications status
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {HasUnreadNotificationsUpdate} action
   */
  @Action(HasUnreadNotificationsUpdate)
  has_unread_notifications_update({ getState, patchState }, action: HasUnreadNotificationsUpdate) {
    const { user } = getState();
    patchState({
      user: {
        ...user,
        hasUnreadNotifications: action.payload.hasUnreadNotifications,
      },
    });
  }

  /**
   * Update auth user
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {UpdateAuthUser} action
   */
  @Action(UpdateAuthUser)
  update_user({ getState, patchState }, action: UpdateAuthUser) {
    const { user } = getState();

    patchState({
      user: { ...user, ...action.payload },
    });
  }

  @Action(SetEnv)
  setEnv({ patchState }, action: SetEnv) {
    patchState({
      env: action.payload,
    });
  }

  @Action(SetPlatform)
  setPlatform({ patchState }, action: SetPlatform) {
    patchState({
      platform: action.payload,
    });
  }

  @Action(SetPlatformOS)
  setPlatformOS({ patchState }, action: SetPlatformOS) {
    patchState({
      platformOS: action.payload,
    });
  }

  /**
   * Set TwoFA options
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaSetOption} action
   */
  @Action(TwoFaSetOption)
  setTwoFaOptions({ patchState }: StateContext<AuthStateModel>, action: TwoFaSetOption) {
    patchState({
      twoFaOptions: action.payload,
    });
  }

  /**
   * Clear TwoFA options
   * @param {getState, patchState}: StateContext<AuthStateModel>
   */
  @Action(TwoFaClearOption)
  clearTwoFaOptions({ patchState }: StateContext<AuthStateModel>) {
    patchState({
      twoFaOptions: null,
    });
  }

  /**
   * Get TwoFA options
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaGetOption} action
   */
  @Action(TwoFaGetOption)
  getTwoFaOptions({ patchState }: StateContext<AuthStateModel>) {
    return this.tfaOptionsService.tfaOptionsGet().pipe(
      tap(
        (options) => {
          patchState({ twoFaOptions: options || undefined });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(TwoFaDeleteOption)
  deleteTwoFaOption({ patchState }: StateContext<AuthStateModel>, action: TwoFaDeleteOption) {
    return this.tfaOptionsService.tfaOptionsDelete({ body: action.payload }).pipe(
      tap(
        () => patchState({ twoFaOptions: null }),
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Verify TwoFA phone number
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaVerifyPhoneNumber} action
   */
  @Action(TwoFaVerifyPhoneNumber)
  verifyTwoFaPhoneNumber({}, action: TwoFaVerifyPhoneNumber) {
    return this.tfaOptionsService.tfaVerifyPhoneNumber({ body: action.payload }).pipe(
      tap(
        (data) =>
          this.localStorageService.set(
            TwoFAKeys.VerifyPhoneRequestToken,
            data.tfaVerifyPhoneRequestToken,
          ),
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Check TwoFA verification code
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaCheckVerificationCode} action
   */
  @Action(TwoFaCheckVerificationCode)
  checkTwoFaVerificationCode({}, action: TwoFaCheckVerificationCode) {
    return this.tfaOptionsService.tfaCheckVerificationCode({ body: action.payload }).pipe(
      tap(
        (data) => {
          this.localStorageService.set(
            TwoFAKeys.VerifyPhoneSuccessToken,
            data.tfaVerifyPhoneSuccessToken,
          );
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Complete TwoFA setup option
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaCompleteSetupOption} action
   */
  @Action(TwoFaCompleteSetupOption)
  completeTwoFaSetupOption(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TwoFaCompleteSetupOption,
  ) {
    return this.tfaOptionsService.tfaCompleteSetupOptions({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get TwoFA question list
   * @param {getState, patchState}: StateContext<AuthStateModel>
   */
  @Action(TwoFaGetQuestionList)
  getTwoFaQuestionList({ patchState }: StateContext<AuthStateModel>) {
    return this.tfaQuestionsService.tfaQuestionsGetList().pipe(
      tap(
        (questions) => {
          patchState({
            twoFaQuestions: questions,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create TwoFA question
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaCreateQuestion} action
   */
  @Action(TwoFaCreateQuestion)
  createTwoFaQuestion(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TwoFaCreateQuestion,
  ) {
    const { twoFaQuestions } = getState();

    return this.tfaQuestionsService.tfaQuestionsCreate({ body: action.payload }).pipe(
      tap(
        (question) => {
          patchState({
            twoFaQuestions: [...twoFaQuestions, question],
            lastAddedQuestion: question,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Remove TwoFA question
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaRemoveQuestion} action
   */
  @Action(TwoFaRemoveQuestion)
  removeTwoFaQuestion(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TwoFaRemoveQuestion,
  ) {
    const { twoFaQuestions } = getState();

    return this.tfaQuestionsService.tfaQuestionsDelete(action.payload).pipe(
      tap(
        () => {
          patchState({
            twoFaQuestions: twoFaQuestions.filter(
              (question) => question?._id !== action.payload.id,
            ),
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Recovery TwoFA check answer
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaRecoveryCheckAnswer} action
   */
  @Action(TwoFaRecoveryCheckAnswer)
  recoveryTwoFaCheckAnswer(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TwoFaRecoveryCheckAnswer,
  ) {
    return this.tfaOptionsService.tfaRecoveryCheckAnswer({ body: action.payload }).pipe(
      tap(
        (data) =>
          this.localStorageService.set(
            TwoFAKeys.RecoveryAnswerSuccessToken,
            data.tfaRecoveryAnswerSuccessToken,
          ),
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Recovery TwoFA verify phone number
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaRecoveryVerifyPhoneNumber} action
   */
  @Action(TwoFaRecoveryVerifyPhoneNumber)
  recoveryTwoFaVerifyPhoneNumber(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TwoFaRecoveryVerifyPhoneNumber,
  ) {
    return this.tfaOptionsService.tfaRecoveryVerifyPhoneNumber({ body: action.payload }).pipe(
      tap(
        (data) =>
          this.localStorageService.set(
            TwoFAKeys.VerifyPhoneRequestToken,
            data.tfaVerifyPhoneRequestToken,
          ),
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Recovery TwoFA complete
   * @param {getState, patchState}: StateContext<AuthStateModel>
   * @param  {TwoFaRecoveryComplete} action
   */
  @Action(TwoFaRecoveryComplete)
  recoveryTwoFaComplete(
    { getState, patchState }: StateContext<AuthStateModel>,
    action: TwoFaRecoveryComplete,
  ) {
    return this.tfaOptionsService.tfaRecoveryComplete({ body: action.payload }).pipe(
      tap(
        () => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Expired session action handler
   * @param  { getState }: StateContext<AuthStateModel>
   * @param  {ExpiredSession} action
   */
  @Action(ExpiredSession)
  expiredSession({ getState }: StateContext<AuthStateModel>, { payload }: ExpiredSession) {
    const state = getState();
    const { loginData, sessionUuid } = payload;
    if (String(sessionUuid) === state.session) {
      this.store.dispatch(new LogoutProcess(loginData || {}));
    }
  }
}
