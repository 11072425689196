import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { RedirectService } from './redirect.service';
import { TenantsState } from '../store/states/tenants.state';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiConfiguration } from '../../api/api-configuration';
import { OnlineOfflineService } from './offline-toast.service';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService {
  isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  error: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject<HttpErrorResponse>(null);
  isMobile: boolean;
  isUserAuth = false;

  constructor(
    private router: Router,
    private redirectService: RedirectService,
    private config: ApiConfiguration,
    private internetConnection: OnlineOfflineService,
    private toastr: ToastrService,
    private store: Store,
    private translocoService: TranslocoService,
  ) {}

  get isOnline(): Observable<boolean> {
    return this.isOnline$.asObservable();
  }

  setStatus(status: boolean, error?: HttpErrorResponse) {
    const isOnline = this.isOnline$.getValue();
    if (isOnline !== status) {
      this.isOnline$.next(status);
    }
    this.error.next(error);
  }

  setNetworkListener(): void {
    let offlineReportsCount = 0;

    this.isOnline.subscribe((status) => {
      if (status) {
        if (this.isUserAuth) {
          this.toastr.clear();
        } else {
          // this.router.navigate(['']);
        }
      }
      offlineReportsCount = status ? 0 : ++offlineReportsCount;
      if (offlineReportsCount === 1) {
        this.handleConnectionError();
      }
    });
  }

  userIsAuthorized(status: boolean): void {
    this.isUserAuth = status;
  }

  handleConnectionError(): void {
    if (this.isUserAuth) {
      this.checkIsHealth();
    } else {
      this.navigateToErrorPage();
    }
  }

  navigateToErrorPage(): void {
    if (!this.isMobile) {
      const tenantName = this.store.selectSnapshot(TenantsState.getTenantName);
      if (environment.subdomains) {
        this.redirectService.go(tenantName, 'connection-lost');
      } else {
        this.router.navigate(['root/connection-lost']);
      }
    }
  }

  checkIsHealth(): void {
    const url = this.config.rootUrl + '/health';
    fetch(url).catch(() => {
      if (!this.internetConnection.isOnline) {
        this.toastr.error(
          this.translocoService.translate('no-internet-connection'),
          this.translocoService.translate('toastr.title-error'),
          {
            disableTimeOut: true,
            tapToDismiss: false,
          },
        );
      }
    });
  }

  setMobilePlatform(): void {
    this.isMobile = true;
  }
}
