import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

import { CommonSuccessResDto } from '../../../api/models/common-success-res-dto';
import { Tenants, TenantsStateModel } from '../models/TenantsState';
import {
  TenantClear,
  TenantExists,
  TenantsGetList,
  TenantsSetList,
} from '../actions/tenants.action';
import { TenantsService } from '../../../api/services/tenants.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@State<TenantsStateModel>({
  name: 'Tenants',
  defaults: {
    tenantName: '',
    routeValid: null,
    tenantList: [],
  },
})
@Injectable()
export class TenantsState {
  /**
   * get tenantName
   * @param  {TenantsStateModel} state
   */
  @Selector()
  static getTenantName(state: TenantsStateModel) {
    return state.tenantName;
  }

  @Selector()
  static getTenantList(state: TenantsStateModel) {
    return state.tenantList;
  }

  constructor(
    private tenantsService: TenantsService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  /**
   * Check if tenant is valid & put result to state
   * @param  {getState, setState}: StateContext<AuthStateModel>
   * @param  {TenantExists} action
   */
  @Action(TenantExists)
  tenantExists({ getState, setState }: StateContext<TenantsStateModel>, action: TenantExists) {
    setState({
      tenantName: '',
      routeValid: null,
      tenantList: [],
    });
    return this.tenantsService.tenantExists({ name: action.payload.name }).pipe(
      tap((result: CommonSuccessResDto) => {
        setState({
          tenantName: action.payload.name,
          routeValid: result.success,
          tenantList: [],
        });
      }),
    );
  }

  @Action(TenantsGetList)
  tenantsGetList({ patchState }: StateContext<TenantsStateModel>) {
    const tenants: Tenants = this.localStorageService.get('tenants') || [];
    this.tenantsService
      .tenantsGetList()
      .pipe(untilDestroyed(this))
      .subscribe((tenantList) => {
        const remappedTenants = tenants.map((tenant) => {
          const resTenant = tenantList.find((item) => item.name === tenant.name);
          if (resTenant) {
            return { ...tenant, ...resTenant };
          }
          return tenant;
        });
        patchState({
          tenantList: remappedTenants,
        });
      });
  }

  /**
   * Clear Tenants state
   * @param  {setState}: StateContext<AuthStateModel>
   */
  @Action(TenantClear)
  tenantClear({ setState }: StateContext<TenantsStateModel>) {
    setState({
      tenantName: '',
      routeValid: null,
      tenantList: [],
    });
  }

  @Action(TenantsSetList)
  tenantsSetList({ patchState }: StateContext<TenantsStateModel>, { payload }) {
    patchState({
      tenantList: payload,
    });
  }
}
