import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Store } from '@ngxs/store';

import { OnlineStatusService } from './online-status.service';
import { ExpiredSession } from '../store/actions/auth.action';

enum SocketEventEnum {
  AUTH_SESSION_EXPIRED = 'notification:send:authSessionExpired',
}

@Injectable()
export class SocketsService {
  constructor(
    private socket: Socket,
    private onlineStatus: OnlineStatusService,
    readonly store: Store,
  ) {}

  connect(accessToken: string, refreshToken: string) {
    this.socket.ioSocket.io.opts.query = `authorization=${accessToken}&token=${refreshToken}`;
    this.socket.connect();
    this.setHandlers();
  }

  disconnect() {
    this.socket.ioSocket.io.opts.query = '';
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }

  get() {
    return this.socket;
  }

  private setErrorHandlers(): void {
    this.socket.on('connect_error', () => {
      this.onlineStatus.setStatus(false);
    });
    this.socket.on('error', (err) => {
      console.log('Socket error:', err);
    });
    this.socket.on('connect', () => {
      this.onlineStatus.setStatus(true);
    });
  }

  private setHandlers(): void {
    this.setErrorHandlers();
    this.socket.on(SocketEventEnum.AUTH_SESSION_EXPIRED, ({ sessionUuid, loginData }) => {
      this.store.dispatch(new ExpiredSession({ sessionUuid, loginData }));
    });
  }
}
