import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  isRedirectActive = false;
  redirectToActiveSprint = false;

  go(tenant: string, endpoint: string) {
    if (environment.production) {
      window.location.href =
        `http${environment.ssl ? 's' : ''}://` +
        (environment.main_host !== environment.base_host
          ? tenant
            ? `${tenant}.${environment.main_host}`
            : environment.base_host
          : (tenant ? `${tenant}.` : '') + environment.base_host) +
        `/${endpoint}`;
    } else {
      window.location.href =
        `http${environment.ssl ? 's' : ''}://${environment.base_host}/` +
        (tenant ? `${tenant}/${endpoint}` : environment.base_host + `/${endpoint}`);
    }
  }

  getRedirectToActiveSprintValue = (): boolean => {
    return this.redirectToActiveSprint;
  };

  setRedirectToActiveSprintValue = (value: boolean): void => {
    this.redirectToActiveSprint = value;
  };
}
