/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TfaQuestionsDbDto } from '../models/tfa-questions-db-dto';
import { TfaQuestionsReqDto } from '../models/tfa-questions-req-dto';

@Injectable({
  providedIn: 'root',
})
export class TfaQuestionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation tfaQuestionsGetList
   */
  static readonly TfaQuestionsGetListPath = '/tfa-questions';

  /**
   * 2fa question get list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaQuestionsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  tfaQuestionsGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<TfaQuestionsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, TfaQuestionsService.TfaQuestionsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TfaQuestionsDbDto>>;
        }),
      );
  }

  /**
   * 2fa question get list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaQuestionsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tfaQuestionsGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<TfaQuestionsDbDto>> {
    return this.tfaQuestionsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TfaQuestionsDbDto>>) => r.body as Array<TfaQuestionsDbDto>),
    );
  }

  /**
   * Path part for operation tfaQuestionsCreate
   */
  static readonly TfaQuestionsCreatePath = '/tfa-questions';

  /**
   * 2fa question create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaQuestionsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaQuestionsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaQuestionsReqDto;
  }): Observable<StrictHttpResponse<TfaQuestionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaQuestionsService.TfaQuestionsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaQuestionsDbDto>;
        }),
      );
  }

  /**
   * 2fa question create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaQuestionsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaQuestionsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TfaQuestionsReqDto;
  }): Observable<TfaQuestionsDbDto> {
    return this.tfaQuestionsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TfaQuestionsDbDto>) => r.body as TfaQuestionsDbDto),
    );
  }

  /**
   * Path part for operation tfaQuestionsDelete
   */
  static readonly TfaQuestionsDeletePath = '/tfa-questions/{id}';

  /**
   * 2fa question delete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaQuestionsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  tfaQuestionsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<TfaQuestionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaQuestionsService.TfaQuestionsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaQuestionsDbDto>;
        }),
      );
  }

  /**
   * 2fa question delete
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaQuestionsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tfaQuestionsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<TfaQuestionsDbDto> {
    return this.tfaQuestionsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<TfaQuestionsDbDto>) => r.body as TfaQuestionsDbDto),
    );
  }

  /**
   * Path part for operation tfaQuestionsUpdate
   */
  static readonly TfaQuestionsUpdatePath = '/tfa-questions/{id}';

  /**
   * 2fa question update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tfaQuestionsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaQuestionsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TfaQuestionsReqDto;
  }): Observable<StrictHttpResponse<TfaQuestionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, TfaQuestionsService.TfaQuestionsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TfaQuestionsDbDto>;
        }),
      );
  }

  /**
   * 2fa question update
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tfaQuestionsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tfaQuestionsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TfaQuestionsReqDto;
  }): Observable<TfaQuestionsDbDto> {
    return this.tfaQuestionsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TfaQuestionsDbDto>) => r.body as TfaQuestionsDbDto),
    );
  }
}
